import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';
import theme, { Typography } from 'utils/theme';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import upgradeSvgs from 'utils/constants/upgradeSvgs';

interface CheckBoxItem {
  title: string;
  text: string;
}

interface ImageTextCheckBoxSectionProps {
  checkBoxes: { bgColor: string; items: CheckBoxItem[] };
  title: string;
  image: string;
  mainColor: string;
  hasPadding?: boolean;
}

const OuterContainer = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  width: 100%;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 71.25rem;
  width: 100%;
  @media ${tablet} {
    align-items: center;
    flex-direction: column-reverse;
  }
`;

const Image = styled(DynamicImage)`
  width: 100%;
  max-width: 37.125rem;
  @media ${tablet} {
    max-width: unset;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 31.125rem;
  width: 100%;
  gap: 1rem;
  @media ${tablet} {
    max-width: unset;
  }
`;

const StyledText = styled.p<{ color?: string; hasPadding?: string }>`
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.048rem;
  max-width: 48rem;
  width: 100%;
  text-align: center;
  span {
    color: ${({ color }) => color};
  }
  padding-bottom: 3rem;
  @media ${tablet} {
    text-align: left;
    font-size: 1.25rem;
    letter-spacing: -0.03rem;
    padding: 2.25rem 0;
  }
`;
interface ChecklistItemProps {
  text: string;
  title: string;
}

const GoalContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

const ItemText = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`;

const BoxTitle = styled.h3`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const SvgContainer = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  @media ${tablet} {
    width: 1.5rem;
    height: 1.5rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const ImageTextCheckBoxSection: FC<ImageTextCheckBoxSectionProps> = ({
  checkBoxes,
  title,
  image,
  mainColor,
  hasPadding,
}) => {
  const { isTablet } = useQuery();

  const renderSvgImage = (svgKey: string) => {
    const Svg = upgradeSvgs[svgKey];
    return <Svg />;
  };
  return (
    <OuterContainer>
      {!isTablet && (
        <StyledText
          dangerouslySetInnerHTML={{ __html: title }}
          color={mainColor}
        />
      )}
      <Container>
        <CheckBoxContainer>
          {checkBoxes.items.map((item, index) => (
            <GoalContainer key={index}>
              <SvgContainer>{renderSvgImage(checkBoxes.bgColor)}</SvgContainer>
              <TextContainer>
                <BoxTitle>{item?.title}</BoxTitle>
                <ItemText>{item?.text}</ItemText>
              </TextContainer>
            </GoalContainer>
          ))}
        </CheckBoxContainer>
        {isTablet && (
          <StyledText
            dangerouslySetInnerHTML={{ __html: title }}
            color={mainColor}
          />
        )}
        <Image src={image} alt="" />
      </Container>
    </OuterContainer>
  );
};

export default ImageTextCheckBoxSection;
